<template>
  <div>
    <div class="modulo10_antigo-checkbox-loading-container" v-show="isLLoading">
      <v-progress-circular indeterminate size="20" ></v-progress-circular>
    </div>

    <v-checkbox
      v-show="!isLLoading"
      :disabled="disabled"
      :label="label"
      :value="value"
      @change="$emit('input', !value)"
    ></v-checkbox>
  </div>
</template>

<script>
import BdgdVersionsService from '@/services/BdgdVersionsService';

export default {
  name: 'Modulo10AntigoCheckbox',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Módulo 10 antigo'
    },
    loading: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: Number,
      default: null,
    },
    month: {
      type: String,
      default: null,
    },
    version: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    disabled: false,
    internalLoading: false,
  }),
  computed: {
    isLLoading() {
      return this.internalLoading || this.loading;
    }
  },
  watch: {
    companyId() {
      this.checkVersion()
    },
    month() {
      this.checkVersion()
    },
    version() {
      this.checkVersion()
    }
  },
  methods: {
    async checkVersion() {
      if (!this.companyId || !this.month || !this.version) return;
      this.internalLoading   = true;
      const versionAlreadyExists = await BdgdVersionsService.versaoModulo10AntigoExists(
        this.companyId,
        `${this.month}-01`,
        this.version,
      );
      this.internalLoading = false;
      this.disabled = versionAlreadyExists;
      this.$emit('input', versionAlreadyExists);
    }
  }
};
</script>

<style scoped>
.modulo10_antigo-checkbox-loading-container {
  margin-top: 24px; 
  margin-bottom: 21px;
}
</style>
